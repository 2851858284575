.alternative-hero {
  background-image: url('../../../assets/hero/white-waves_pattern.png');
  background-repeat: repeat;

  h1 {
    font-size: 2.8rem;
    color: $secondary-blue;
    line-height: 3.2rem;
    @media only screen and (max-width: 640px) {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }

  h2 {
    font-size: 1.4rem;
    font-weight: 300;
  }

  .left-panel {
    width: 568px;
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }

  .bbb-logo {
    width: 120px;
    height: 70px;
  }
}

