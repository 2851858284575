.email-capture-success {
  h1 {
    font-size: 2.25rem;
    line-height: 2.25rem;
    @media only screen and (max-width: 640px) {
      font-size: 1.8rem;
    }
  }

  .reservation-number {
    background-image: url('../../assets/logos/g-grey.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 188px;
    .waiting-list-number {
      font-size: 1.3rem;
    }
  }

  .call-to-action-button {
    @media only screen and (max-width: 640px) {
      width: 100%;
      margin-top: 1rem;
    }
  }
}
