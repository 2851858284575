@import './ComparisonChart/comparisonChart';

.faq {
  .in-page-nav {
    li {
      color: $primary-blue;
      font-size: 1.125rem;
    }
  }
}
