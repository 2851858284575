.email-capture-form {
  .email-capture-input {
    width: 362px;
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }

  .term-acceptance {
    font-size: 0.75rem;
    text-align: center;
  }
}
