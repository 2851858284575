@import './variables';
@import '../components/LandingPage/index.scss';
@import '../components/common/index.scss';
@import '../pages/index.scss';

html {
  min-height: 100%;
  width: 100%;
  background-color: $light-grey;
}

h1 {
  letter-spacing: 0.1rem;
}

.body-container {
  min-height: 72vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h2 {
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  @media only screen and (max-width: 640px) {
    line-height: 2rem;
  }
}

a {
  &:hover {
    color: $secondary-blue;
  }
}

p {
  font-weight: 400;
}

.color-blue {
  color: $primary-blue;
}

.color-dark-blue {
  color: $secondary-blue;
}

.bg-dark-blue {
  background-color: $dark-blue;
}

.bg-primary-blue {
  background-color: $primary-blue;
}

.select {
  width: 362px;
}

.validation {
  background-color: #F8E4E4;
  color: $red;
  border: 0.0625rem solid $red;
  padding: 10px;
  position: relative;
  top: -1rem;
  font-size: 0.8rem;

  &.top-adjustment {
    top: 0;
  }
}

.validation-warning {
  background-color: #F8EEDE;
  color: $orange;
  border: 0.0625rem solid $orange;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  font-size: .9rem;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}

.validation-border {
  border: 1px solid $red;
}

.rift-it {
  font-family: 'Rift';
}
