$primary-blue: #49A4D5;
$secondary-blue: #005AA3;
$light-bg-blue: #F2F8FD;
$dark-blue: #132741;
$light-grey: #F8F8F8;
$light-grey-color: #B2B2B2;
$medium-grey: #C8CCD4;
$grey: #979797;
$green: #3DD17B;
$green-bg: #F8FDFA;
$dark-green: #247B4A;
$red: #E23333;
$orange: #FD8200;
$dark-orange: #E87803;
$orange-bg: #FFFDFC;
$vibrant-pink: #E30051;
$hover-vibrant-pink: #CF064E;
$purple: #8A21D1;
$yellow: #FFF040;
