@import './DropdownInput/dropdownInput';
@import './EmailCaptureForm/email-capture-form';
@import './LegalDisclosure/legal-disclosure';
@import './Loading/loading';
@import './TextInput/textInput';
@import './TrophyGraphic/trophy-graphic.scss';
@import './HowItWorksVideo/how-it-works-video';

.call-to-action-button {
  border-radius: 0.5rem;
  background-color: $orange;
  box-shadow: 5px 5px 2px 1px rgba(0, 0, 0, 0.1);
  color: white;
  padding: 1rem;
  width: 177px;
  cursor: pointer;
  outline: none;
  font-weight: bold;

  &:hover {
    background-color: $dark-orange;
    color: white;
  }

  a {
    &:hover {
      color: white;
    }
  }
}
