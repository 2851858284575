.video-sales-letter-landing-page {
  h1 {
    font-size: 2.25rem;
    line-height: 2.25rem;
    color: $secondary-blue;
    @media only screen and (max-width: 640px) {
      font-size: 1.8rem;
    }
  }

  h2 {
    font-size: 1.375rem;
    letter-spacing: normal;
    font-weight: 300;
  }

  .video {
    width: 600px;
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }

  .call-to-action-button {
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }
}
