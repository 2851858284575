.share-links {
  .copy-link {
    width: 369px;
    background-color: #F7F7F7;
    color: #9D9D9D;
    border-radius: 0.5rem;
    border: 1px solid #979797;
    margin: 0;
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }

  button {
    outline: none;
  }

  .call-to-action-button {
    position: relative;
    top: 0.1rem;
    @media only screen and (max-width: 640px) {
      margin-top: 2rem;
    }
  }

  input {
    width: 100%;
    background-color: transparent;
    outline: none;
  }
}
