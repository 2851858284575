.alternative-email-capture-landing-page {
  .form-width {
    margin: 0;
  }

  .inner-width {
    width: 913px;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .term-acceptance {
    text-align: left;
    @media only screen and (max-width: 640px) {
      text-align: center;
    }
  }

  .call-to-action-button {
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
