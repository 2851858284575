@import './AlternativeHero/alternative-hero';
@import './Hero/hero';
@import './ShareLinks/share-links';
@import './VideoModal/video-modal';

.container {
  &.inner-width {
    width: 865px;
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }
}

.form-width {
  max-width: 550px;
  margin: auto;
}

.call-to-action-button {
  width: 177px;
  position: relative;
  top: -0.2rem;
}
