.comparison-chart {
  font-size: 0.8125rem;

  .header {
    font-size: 0.625rem;
  }

  .first-col-width {
    width: 180px;
  }

  .sub-col-width {
    width: 164px;
  }

  img {
    width: 11.3rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }

  .bg-gray {
    background-color: #D8D8D8;
  }

  .top-border {
    border-radius: 0.3rem;
    border-top: 0.2rem solid $primary-blue;
  }

  .left-border {
    border-left: 0.2rem solid $primary-blue;
  }

  .right-border {
    border-right: 0.2rem solid $primary-blue;
  }

  .bottom-border {
    border-radius: 0.3rem;
    border-bottom: 0.2rem solid $primary-blue;
  }
}

