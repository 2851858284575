.hero {
  background-image: url('../../../assets/hero/email-capture_hero.jpg');
  height: 50rem;
  text-align: center;
  @media only screen and (max-width: 640px) {
    background-image: url('../../../assets/hero/email-capture-hero_mobile.jpg');
    height: auto;
  }

  h1 {
    font-size: 3rem;
    line-height: 3rem;
    @media only screen and (max-width: 640px) {
      font-size: 2rem;
      line-height: 2.2rem;
    }
  }

  h2 {
    font-weight: 100;
    @media only screen and (max-width: 640px) {
      font-size: 1rem;
      letter-spacing: normal;
      line-height: 1.4rem;
      font-weight: 400;
    }
  }

  ul {
    text-align: center;
    display: block;

    li {
      margin-right: 2.5rem;
      display: inline-block;
      font-weight: 600;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
      color: white;

      @media only screen and (max-width: 640px) {
        margin-right: 1rem;
      }

      &:first-child {
        margin-left: 2.5rem;
        @media only screen and (max-width: 640px) {
          margin-left: 1rem;
        }
      }
    }
  }

  .by-entering {
    @media only screen and (max-width: 640px) {
      font-size: 0.75rem;
    }
  }

  .call-to-action-button {
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }
}
